import React from "react"
import { UsecasesHeaderSection, UsecasesHeroCaseSection, UsecasesHeroSection } from "@/layout"

const UsecasesPage: React.FC = () => {
    return (
        <div className={'container-usecases'}>
            <UsecasesHeroSection/>
            <UsecasesHeaderSection/>
            <UsecasesHeroCaseSection/>
        </div>
    )
}

export default UsecasesPage
